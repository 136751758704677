import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
// @mui
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useBoolean } from 'src/hooks/use-boolean';
// components
import LoadingButton from '@mui/lab/LoadingButton';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import { useEffect } from 'react';
import FormProvider, { BlockItem, RHFTextField } from 'src/components/hook-form';
import { useSnackbar } from 'src/components/snackbar';
import { LISENCE_KEY } from 'src/constants/key';

// ----------------------------------------------------------------------

export default function KeyLisence() {
  const openForm = useBoolean();

  const { enqueueSnackbar } = useSnackbar();

  const KEY_STORAGE = localStorage.getItem('KEY_STORAGE');

  const Schema = Yup.object().shape({
    name: Yup.string().required('Bạn chưa nhập key kích hoạt phần mềm !'),
  });

  const defaultValues = {
    name: '',
  };

  const methods = useForm({
    resolver: yupResolver(Schema),
    defaultValues,
  });

  useEffect(() => {
    if (KEY_STORAGE && LISENCE_KEY.includes(KEY_STORAGE)) {
      openForm.onFalse();
    } else {
      openForm.onTrue();
    }
  }, []);

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data: any) => {
    try {
      if (LISENCE_KEY.includes(data.name)) {
        localStorage.setItem('KEY_STORAGE', data.name);
        enqueueSnackbar(`Đã kích hoạt thành công !`);
        reset();
        openForm.onFalse();
      } else {
        enqueueSnackbar(`Sai mã kích hoạt vui lòng thử lại !`, { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar('Không tìm thấy kết nối mạng !', { variant: 'error' });
    }
  });
  return (
    <>
      <Dialog
        fullWidth
        maxWidth={false}
        open={openForm.value}
        PaperProps={{
          sx: { maxWidth: 620 },
        }}
      >
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <DialogTitle>Phần mềm chưa kích hoạt</DialogTitle>

          <DialogContent>
            <Stack spacing={2}>
              <BlockItem label="Mã kích hoạt :" required>
                <RHFTextField name="name" label="Nhập vào mã kích hoạt..." />
              </BlockItem>
            </Stack>
          </DialogContent>

          <DialogActions>
            <LoadingButton type="submit" color="primary" variant="contained" loading={isSubmitting}>
              Gửi yêu cầu kích hoạt
            </LoadingButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
}
