export const LISENCE_KEY = [
  'lzciavdp2r56uuyvt',
  'lzciavdp3cyarqm36',
  'lzciavdp1jdg2p90s',
  'lzciavdpoeihikky',
  'lzciavdp1c3b3k44z',
  'lzciavdp30f4jry6h',
  'lzciavdp3516utdj2',
  'lzciavdpfr2tcg75',
  'lzciavdp1p8wes1mt',
  'lzciavdpm9pts9bv',
  'lzciavdq355ylotej',
  'lzciavdq2cdduozi8',
  'lzciavdq38gspklpu',
  'lzciavdq2eci5b091',
  'lzciavdq2l3gux7bd',
  'lzciavdq1dj09x2td',
  'lzciavdqfmcf5zgs',
  'lzciavdq20vxml2cr',
  'lzciavdq2d84qs2ej',
  'lzciavdq2vhtu2iko',
  'lzciavdq1ou9agyke',
  'lzciavdqup3uy9y1',
  'lzciavdq3hgselbdz',
  'lzciavdqht4nij6p',
  'lzciavdqdt4iyw04',
  'lzciavdq1kegrukpz',
  'lzciavdqecawecs5',
  'lzciavdqya7b9mwv',
  'lzciavdqmxp46xsl',
  'lzciavdq1wuzrbnpg',
  'lzciavdq140483a0m',
  'lzciavdq14mfa5ndt',
  'lzciavdq20kdb088p',
  'lzciavdqu7fv1cmr',
  'lzciavdq2n5nc12si',
  'lzciavdqrzs493ss',
  'lzciavdq2kuifdjf3',
  'lzciavdq27rhz6bpl',
  'lzciavdq2qpgwbu3q',
  'lzciavdq2eggplsdx',
  'lzciavdq1nkln73o1',
  'lzciavdq19r6lsyy3',
  'lzciavdq2xycfjt02',
  'lzciavdq3bp7ct9pf',
  'lzciavdq24vq7musg',
  'lzciavdqfg6s09dt',
  'lzciavdq2m6nkaxan',
  'lzciavdq1uyh1ljlz',
  'lzciavdq2n3ub7bdc',
  'lzciavdq2wg495npg',
  'lzciavdqotsph3ao',
  'lzciavdq2s330uytb',
  'lzciavdq21ypipsa2',
  'lzciavdq11xmebwtw',
  'lzciavdq20l55nwzz',
  'lzciavdq2ca7tujd8',
  'lzciavdq32uwqxbie',
  'lzciavdq2g9jb2xnj',
  'lzciavdqzpnud7xv',
  'lzciavdq1su34a0o6',
  'lzciavdq2e1epebny',
  'lzciavdq2l189anit',
  'lzciavdq2vpew0f5n',
  'lzciavdq2bpbzuswd',
  'lzciavdq2lq6fqhno',
  'lzciavdq1x0w08s5z',
  'lzciavdq1fcoio9r7',
  'lzciavdq1mpzy1nu8',
  'lzciavdq2kpthwn5g',
  'lzciavdquzrqc3lx',
];
