// @mui
import Box from '@mui/material/Box';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import { useSettingsContext } from 'src/components/settings';
// config
import { IS_HAS_MENU } from 'src/config-global';
//
import Main from './main';
import Header from './header';
import NavMini from './nav-mini';
import NavVertical from './nav-vertical';
import NavHorizontal from './nav-horizontal';
import SupportPopup from './support-popup';
import KeyLisence from './key-lisence';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function HomeLayout({ children }: Props) {
  const settings = useSettingsContext();

  const lgUp = useResponsive('up', 'lg');

  const nav = useBoolean();

  const isHorizontal = settings.themeLayout === 'horizontal';

  const isMini = settings.themeLayout === 'mini';

  const renderNavMini = <NavMini />;

  const renderHorizontal = <NavHorizontal />;

  const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />;

  if (isHorizontal) {
    return (
      <>
        {IS_HAS_MENU && <Header onOpenNav={nav.onTrue} />}

        {IS_HAS_MENU && <>{lgUp ? renderHorizontal : renderNavVertical}</>}

        <SupportPopup />
        <KeyLisence />

        <Main>{children}</Main>
      </>
    );
  }

  if (isMini) {
    return (
      <>
        {IS_HAS_MENU && <Header onOpenNav={nav.onTrue} />}

        <Box
          sx={{
            minHeight: 1,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          {IS_HAS_MENU && <>{lgUp ? renderNavMini : renderNavVertical}</>}

          <SupportPopup />
          <KeyLisence />

          <Main>{children}</Main>
        </Box>
      </>
    );
  }

  return (
    <>
      {IS_HAS_MENU && <Header onOpenNav={nav.onTrue} />}

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        {IS_HAS_MENU && renderNavVertical}

        <SupportPopup />
        <KeyLisence />

        <Main>{children}</Main>
      </Box>
    </>
  );
}
